.custom-drawer {
  width: 50% !important; /* Set drawer content width to 80% on large screens */
  margin: 0 auto !important; /* Center the drawer horizontally */
  border-top-left-radius: 16px !important; /* Add top-left border radius */
  border-top-right-radius: 16px !important; /* Add top-right border radius */
  font-family: "Inter", sans-serif !important;
}

/* Box styling inside the drawer */
.drawer-box {
  /* height: 27rem; */
  min-height: 21rem;
  padding-left: 30px;
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 15px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.drawer-button {
  background-color: var(--links) !important; /* Custom button color */
  color: #fff; /* White text color */
  width: 100%; /* Make the button full width */
  padding: 10px 0; /* Add vertical padding */
  border-radius: 8px; /* Rounded corners for the button */
  border: none; /* Remove default button border */
  margin-top: 1rem !important;
  /* margin-bottom: 1rem !important; */
}

.drawer-address-line {
  color: var(--links) !important;
}
.drawer-title-box{
  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.heading {
  font-weight: bold !important;
  font-family: "Inter", sans-serif !important;
  font-size: 1.2rem !important;
  margin-left: 10px !important;
}

.address-input {
  margin-top: 20px;
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.address-input {
  position: relative;
  z-index: 1000; /* Ensure suggestions appear on top */
}

.house-landmark {
  display: flex;
  gap: 16px;
}

/* .house-landmark {
    display: flex;
    gap: 16px; Adjust the gap between the fields as needed
} */

/* .delivery-input-fields {
    flex: 1;
} */

.delivery-landmark-field {
  flex: 1;
}

.delivery-input-fields {
  margin-right: 2rem;
  /* flex: 1; */
  /* border: 1px solid #c9c8c8 !important;
  margin-right: 2rem !important;
  border-radius: 10px !important; */
}

.search-location{
  display: flex;
}
.circular-prog-center{
  align-items: center;
}
.drawer-address-error {
  color: red;
}

/* Apply 99% width for screens below 600px (mobile and small tablets) */
@media (max-width: 600px) {
  .custom-drawer {
    width: 99% !important; /* Set drawer content width to 99% for small screens */
    margin: 0 auto !important; /* Center the drawer horizontally */
  }
  .house-landmark {
    display: block;
  }
  .drawer-box {
    min-height: 26.5rem;
  }
  .delivery-input-fields {
    margin-right: 0rem;
  }
}

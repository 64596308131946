.vriyebal-name h6{
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 6px;
    font-family: "Inter", sans-serif !important;
}
.set-addportion-box{
    text-align: center;
}
.setbgpor{
    background: #fff;
    padding: 2px 10px;
    border-radius: 16px;
    margin-bottom: 0.5rem;
}
.popup-main{
    display: flex;
}
.popup-img-setup{
    width: 40%;
}
.vnf{
    margin-top: 10px;
}
.popup-addons-content-box{
    width: 60%;
    height: 450px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-left: 20px;
    padding-right: 20px;
}
.popup-title-set{
    margin-top: 8px;
    margin-bottom: 10px;
}
.veriant-main{
    background: #fff;
    width: 94%;
    padding: 10px;
    border-radius: 10px;
}
.vriyebal-name .add-ons-dialogue{
    width: calc(97% + 8px) !important;
}
.popup-discription p{
    font-size: 0.90rem;
    display: block;
    text-align: justify;
    line-height: 1.3;
}
.addportion-t-box{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: auto;
    /* display: inline-block;
    background: #fff; */
    position: relative;
    z-index: 1;
    padding: 0 15px;
}
.addportion-t-box h6{
    font-weight: 600;
    font-family: "Inter", sans-serif !important;
    font-size: 0.88rem;
}
.new-seprators p{
    font-family: "Inter", sans-serif !important;
}
.new-seprators{
    border: 1px solid black;
    background: #fff;
    border-radius: 10px;
    margin: auto;
    padding: 0 10px;
    position: inherit;
    width: auto;
    z-index: 1;
}
.new-seprator:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #cbcbcb;
    left: 0px;
    top: 50%;
    transform: translate(0%, -50%);
}
.portion-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 16px;
    margin: 0px;
    height: 110px;
    border: 2px solid transparent;
    border-radius: 8px;
    gap: 6px;
    transition: border-color 0.3s;
}
/* .css-a5rdam-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
}
.css-1rcscmn-MuiPaper-root{
    padding: 0px !important;
}
.css-a5rdam-MuiGrid-root{
    margin-left: 0 !important;
    width: calc(98% + 8px) !important;
}
.css-a5rdam-MuiGrid-root>.MuiGrid-item{
    padding-right: 8px;
} */
.portion-tile.selected {
    border-color: green;
    /* background-color: var(--offerhover); */
    background-color: var(--s_color);
    background-image: url(../../assets/checked.svg);
    background-position: top 4px right 4px;
    background-size: 21px 22px;
    background-repeat: no-repeat;
}
.modifier-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px !important;
    cursor: pointer;
    padding: 8px;
    margin: 0;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s;
    box-shadow: none !important;
    height: 26px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.modifier-tile.selected {
    border-color: green;
    background-color: var(--offerhover);
    padding-left: 10px !important;
    padding-right: 10px !important;
}
/* .css-1equabv-MuiGrid-root{
    flex-basis: 20% !important;
} */
@media (min-width: 320px) and (max-width: 768px) {
/* .css-1uwgr7b-MuiTypography-root{
    font-size: 0.80rem;
}
.css-1equabv-MuiGrid-root{
    flex-basis: 16%;
} */
.portion-tile{
    height: 85px;
}
/* .css-a5rdam-MuiGrid-root>.MuiGrid-item {
    padding-left: 5px !important;
} */
.popup-main{
    display: block;
}
.popup-img-setup{
    width: 100%;
}
.vnf{
    margin-top: 10px;
}
.popup-addons-content-box{
    width: 100%;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 0;
    padding-right: 0;
}
.veriant-main{
    width: 92%;
}
}

.portion-title-dm {
    line-height: 1.2 !important;   
}
.ps-order-error-container{
    width: 40% !important;
    margin: 0 auto !important;
    padding: 20px !important;
    text-align: center !important;
    border-radius: 10px !important;
  }
  
  .ps-icon-container {
    margin-bottom: 15px !important;
  }
  
  .ps-success-icon {
    font-size: 6.8rem !important;
    color: #4caf50 !important;
  }
  
  .ps-title {
    color: #333 !important;
    margin-bottom: 10px !important;
    font-weight: 700 !important;
  }
  
  .ps-order-id {
    color: #777 !important;
    margin-bottom: 20px !important;
    font-size: 0.99rem !important;
  }
  
  .ps-order-id span{
    color: #777 !important;
    font-size: 0.99rem !important;
  }
  .ps-go-to-menu-button {
    margin-bottom: 20px !important;
  }
  .button-block{
    margin-top: 1rem;
    gap: 3rem;
    display: flex;
    justify-content: center !important;
  }
  .payment-success-od {
    color: var(--links) !important;
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    margin-top: 0.3rem !important;
    margin-bottom: 1rem !important;
  }

    /* Styling for buttons */
.ps-go-to-menu-button {
  gap: 8px; /* Space between icon and text */
  font-size: 0.7rem !important;
  text-transform: none !important;
  width: 50% !important;
}

.ps-go-to-menu-button:hover {
background-color: #388e3c;
color: #fff;
}

  @media (min-width: 320px) and (max-width: 860px) {
    .ps-order-error-container{
      width: 86% !important;
    }
  }
  
.set-otp-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.set-otp-box input{
    margin: 4px;
    width: 100%;
    height: 40px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    outline: none;
    text-align: center;
}
.css-0 {
    text-align: center;
}

@media (min-width: 320px) and (max-width: 860px) {
    .set-otp-box input {
        margin: 0 5px;
        width: 100%;
        height: 42px;
        border: 1px solid #c4c4c4;
        outline: none;
        text-align: center;
        padding: 2.5px 1px !important;
    }
    .main-box input{
        padding: 12.5px 4px !important; 
    }
}
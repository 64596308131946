.model-content-box{
    width: 68%;
    margin-right: 10px;
    border-right: 1px dashed red;
}
.button-box{
    width: 30%;
    margin-top: 2px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;

}
/* .apply-offers-setup .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 470px !important;
} */
.offer-name{
    font-weight: 700;
    color: #201C1D;
    font-size: 0.99rem;
    text-transform: capitalize;
}
.offer-content{
    font-weight: 400;
    color: #201C1D;
    font-size: 0.9rem;
    margin-right: 4%;
}
.offer-coupon-code{
    font-weight: 600;
    color: #201C1D;
    font-size: 0.9rem;
    margin-right: 4%;
}
.apply-coupon-div {
    margin: 10px 0px;
    list-style-type: none;
    color: #000;
    background-color: #fff;
    position: relative;
}
.apply-coupon-div:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f1f0f5;
    left: -7px;
    bottom: -10px;
    border-radius: 50%;
}
.apply-coupon-div:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f1f0f5;
    right: -7px;
    bottom: -10px;
    border-radius: 50%;
}
.apply-coupon-div li {
    padding: 10px 20px 10px 20px;
    position: relative;
    display: flex;
}
ul{
    list-style: none;
    padding: 0;
}
.apply-coupon-div li:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f1f0f5;
    left: -7px;
    top: -20px;
    border-radius: 50%;
}
.apply-coupon-div li:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f1f0f5;
    right: -7px;
    top: -20px;
    border-radius: 50%;
}
.offer-background{
    background: #f1f0f5;
}

.offer-button-model {
    background-color: var(--darkblue) !important;
}
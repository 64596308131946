.login-page {
    background-image: url('../assets/background_image.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    flex-direction: column;
    max-width: 40%;
    align-items: center;
    justify-content: center;
    margin: auto;
    /* margin-top: 25px; */
    padding: 24px; /* 3 * 8px (default MUI spacing unit) */
    border-radius: 20px;
    background-color: #F6F6F6;
    transition: box-shadow 0.3s ease-in-out;
  }

  .playstore-links-mainbox{
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 12px;
  }
 .left-part-login{
    display: flex;
    flex-wrap: wrap;
    width: 40%;
 }

.logo{
    width: 50%;
    text-align: center;
    margin: auto;
    /* margin-right: 20px; */
}
.play-store-img{
    width: 50%;
    height: auto;
    text-align: right;
}
.app-store-img{
    width: 50%;
    height: auto;
    text-align: left;
}
.play-store-img img, .app-store-img img{
    width: 50%;
}
.main-box {
    width: 80%;
    /* padding: 10%; */
    display: flex;
    align-items: center;
}

.main-box h4{
    font-weight: 700;
    font-size: 1.6rem;
    padding: 12.5px 14px !important;
}
.main-box button{
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
}
.main-box input{
    background-color: #fff;
    color: #000;
    height: 16px;
    border-radius: 10px;
    outline: none;
    padding: 8.5px 4px;
    border: 1px solid #c3c3c3;
    font-size: 0.8rem;
}
.store-input input{
    cursor: pointer;
}
.sm-location-modal{
    border-radius: 20px !important;
}  
.login-button-icon {
  display: flex;
  align-items: center;
  gap: 8px; 
}

@media only screen and (max-width: 768px) {
    .main-box {
      /* width: 90%; */
      display: flex;
      align-items: center;
      flex-direction: column;
      /* margin-top: -120px !important; */
    }
    .play-store-img{
        width: 100%;
    }
    .app-store-img{
        width: 100%;
    }
    .play-store-img img, .app-store-img img{
        width: 100%;
    }
    .box{
        max-width: 90%;
    }
    .left-part-login{
        margin-bottom: 20px;
     }
     .left-part-login .logo{
        width: 26% !important;
    }
     .login-header-text{
        font-size: 1rem !important;
     }
     .main-box h4 {
        padding: 0.5px 14px !important;
    }
     .login-button-text {
        font-size: 0.8rem !important;
      }
      
      .login-button-icon {
        display: flex;
        align-items: center;
        gap: 8px; 
      }
      
  }
  
/* .login-page .MuiOutlinedInput-root {
    border: 1px solid #000000;
    border-radius: 8px;
} */

@media (min-width: 320px) and (max-width: 1023px) {
    .main-box input{
        padding: 2.5px 1px !important;
    }
    .main-box {
        width: 80%;
        /* padding: 10%; */
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        /* margin-top: -30px; */
        margin-top: -94px;
    }
    .main-box button{
        margin-top: 2px;
    }
    .left-part-login {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
    }
    .box{
        max-width: 80%;
    }
    
    
}
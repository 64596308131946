.sp-kms{
    text-align: end;
    font-size: small;
    color: var(--links);
}
.d-flex{
    display: flex;
    justify-content: space-between;
    gap: 2.2rem;
}
.geo-error{
    color: red; 
    margin-left: 1.5rem !important;
}
.geo-success{
    color: green; 
    margin-left: 1.5rem !important;
}
.sp-search-nearby{
    display: flex;
    flex-direction: column-reverse;
}
.nearby-button{
    /* margin-bottom: 0.7rem !important; */
    font-size: 0.9rem !important;
}
.sp-back-button{
    display: flex;
    flex-direction: row-reverse;
}
.sp-destinationtime{
    font-size: smaller;
}
.sm-location-name {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
}
.sm-location-modal {
    border-radius: 20px !important;
}
.store-locater-box{
    border-radius: 20px !important;
}
.store-search-input {
    border: 1px solid #c3c3c3 !important;
    border-radius: 5px !important;
}
.store-search-input input{
    font-size: 0.8rem !important;
}
.sm-location-address {
    font-size: 0.80rem !important;
}
.sm-location-title {
    font-size: 1rem !important;
    padding: 10px 24px !important;
}
.distance-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem
}
@media (min-width: 320px) and (max-width: 599px) {
    .d-flex{
        display: flex;
        justify-content: space-between;
        gap: 0.2rem;
    }
}
@media (min-width: 600px) and (max-width: 1024px) {
    .d-flex{
        display: flex;
        justify-content: space-between;
        gap: 0.2rem;
    }
}
@media (min-width: 1025px) and (max-width: 1600px) {
    .d-flex{
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
    }
}
.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-style {
  width: 92%;
  padding: 6px 16px; /* Reduced padding for less spacing */
  margin-bottom: 16px;
  border-radius: 12px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.order-title {
  font-weight: bold;
  color: black;
}

.order-status {
  color: var(--links);
  font-weight: 500;
}

.order-status-acknowledged {
  color: var(--links); /* Green */
  font-weight: 500;
}

.order-status-food-ready {
  color: #ff9800; /* Orange */
  font-weight: 500;
}

.order-status-dispatched {
  color: #007200; /* Blue */
  font-weight: 500;
}

.order-status-completed {
  color: var(--links); /* Teal */
  font-weight: 500;
}

.order-status-cancelled {
  color: #f44336; /* Red */
  font-weight: 500;
}

.order-status-placed {
  color: var(--links);
  font-weight: 500;
}

.order-date {
  color: #757575 !important;
  margin-bottom: 4px !important;
}

.history-store-info {
  color: #757575;
  font-weight: 500;
  margin-top: 10px !important;
}

.order-amount {
  font-weight: bold;
  color: #333;
}

.view-order {
  cursor: pointer;
  font-weight: bold;
  color: #1976d2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-large {
  font-size: 40px;
  color: #ff9800;
}

.divider-spacing {
  margin: 8px 0 !important; /* Reduced space between sections */
}

.icon-btn {
  color: #007200;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.oh-box{
  background-color: white;
  border-radius: 10px;
}
.oh-backbutton{
  margin-bottom: 20px;
  margin-top: 20px;
  width: 95%;
}
.order-detail-dv {
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 12px 20px rgb(0 0 0 / 6%);
  border-radius: 12px !important;
  margin-bottom: 25px;
  min-width: 400px;
  max-width: 400px;
}
@media (max-width: 500px) {
  .order-detail-dv {
    min-width: 335px;
    max-width: 335px;
  }
}
.order-detail-body{
    padding: 15px 15px;
}
.order-detail-header{
    padding: 15px 10px 0px 10px;
    display: flex;
}
.order-detail-head-lft{
    width: 100%;
    max-width: 65%;
    font-size: 14px;
}
.order-detail-head-rgt{
    width: 100%;
    max-width: 35%;
    text-align: right;
    font-size: 13px;
}
.order-detail-head-lft ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
}
.order-detail-head-detail {
  font-weight: 600;
}
.order-detail-head-detail span {
  display: block;
  color: #848484;
  font-size: 13px;
  font-weight: 400;
}
.inner-body-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
}
.inner-body-lft {
  color: #848484;
}
.order-buttons {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
  display: flex;
  justify-content: space-around;
  border-top: 1px dashed #E7E7E7;
  padding-top: 10px;
  margin-top: 15px;
  font-size: 14px;
}
.order-buttons li {
  display: flex;
  align-items: center; /* Align icon and text on the same line */
  gap: 5px; /* Space between the icon and the text */
  cursor: pointer; /* Show pointer cursor on hover */
  color: #6a994e; /* Set a color for the button text */
  font-weight: bold;
  transition: color 0.2s ease;
}

.order-buttons li:hover {
  color: #46b346; /* Change text color on hover */
}

.order-buttons li svg {
  font-size: 16px; /* Adjust icon size */
}
.oh-list-icon svg{
  font-size: 3rem;
  color: #6a994e;
  margin-right: 10px;
}
.style-oh-detail{
    border-top: 1px dashed #E7E7E7;
    padding-top: 15px;
}
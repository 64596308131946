.featured-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Horizontal scroller container */
.featured-scroller {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    gap: 15px;
    /* scrollbar-color: #000 transparent; */
    /* scrollbar-width: thin; */
    /* border-radius: 10px; */
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;
}

.featured-scroller::-webkit-scrollbar {
    height: 8px; /* Adjust scrollbar height */
    width: 6px;
}

.featured-scroller::-webkit-scrollbar-thumb {
    /* background-color: #888; /* Scrollbar thumb color */
    /*border-radius: 4px; */
    background: #666;
  border-radius: 20px;
}
/***** scroll setup start ***/
/* Target the scrollbar of elements with the class .css-h4y409-MuiList-root */
.featured-scroller::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
    height: 4px; /* Height of the horizontal scrollbar */
}

/* The scrollbar track (background of the scrollbar) */
.featured-scroller::-webkit-scrollbar-track {
    background: #ddd; /* Track color */
    border-radius: 20px; /* Rounded corners for the track */
}

/* The draggable part of the scrollbar (the thumb) */
.featured-scroller::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 20px; /* Rounded corners for the thumb */
    /*border: 3px solid #ddd;*/ /* Gives some space around the thumb */
}

/* On hover, make the scrollbar thumb darker for better visibility */
.featured-scroller::-webkit-scrollbar-thumb:hover {
    background-color: #0c8c45;
}

/* Optional: Style for the scrollbar's corner (where the horizontal and vertical scrollbars meet) */
.featured-scroller::-webkit-scrollbar-corner {
    background-color: #ddd;
}

/**** scroll setup end ***/
.p-b-setup{
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
    text-align: center;
    align-items: center;
    margin-bottom: 10px;
}
.p-b-setup p{
    margin: 0;
}

.featured-scroller::-webkit-scrollbar-track {
    background: transparent;
}

.featured-card {
    flex: 0 0 auto;
    width: 200px;
    background-color: white;
    /* background-color: #edeec9; */
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
}

.featured-card:hover {
    transform: translateY(-5px);
}

.image-container {
    position: relative;
    width: auto;
    /* height: 170px; */
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.customizable-tag {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 3px;
}

.product-info {
    /* padding: 10px; */
    text-align: center;
}

.product-name {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 5px 0;
    height: 40px;
}


.add-to-cart-btn {
    background: var(--darkblue);
    color: white;
    padding: 5px 12px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1.4rem;
    transition: background-color 0.3s;
    margin-top: 10px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    margin: 0;
}

.add-to-cart-btn:hover {
    background-color: #006630;
}

.recommendedproduct-image {
    width: 100% !important;
    /* height: 80px !important; */
    height: 180px !important;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
  }

  .recommended-skeleton-card {
    flex: 0 0 auto;
    width: 200px;
    background-color: #fefcfb;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
    border: 1px solid #fefcfb;
}

@media (min-width: 320px) and (max-width: 400px) {
    .featured-card{
        width: 60%;
    }
}
@media (min-width: 400px) and (max-width: 500px) {
    .featured-card{
        width: 50%;
    }
}
@media (min-width: 500px) and (max-width: 600px) {
    .featured-card{
        width: 40%;
    }
}
@media (min-width: 600px) and (max-width: 700px) {
    .featured-card{
        width: 35%;
    }
}

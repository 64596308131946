/* CartPage.css */
.cart-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}
.cart-container h6{
  font-size: 0.99rem !important;
  font-weight: 600;
}
.cart-items{
  background: #fff;
  border-radius: 10px;
  height: fit-content;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.backpart{
  width: 100%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 10px;
}
.backiconset{
  width: 36px;
  height: auto;
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.backiconset img{
  width: 100%;
}
.brandnameback{
  width: 100%;
}
.brandnameback h3{
  margin: 0;
  font-size: 0.99rem;
  color: darkgreen;
}
.brandnameback p{
  margin: 0;
  font-size: 0.88rem;
  color: #848484;
}
.input-box-setup{
  box-shadow: none !important;
  border: 1px dotted #c3c3c3 !important;
}
.justification-cart {
  justify-content: space-between;
}
.cart-summary {
    position: sticky;
    top: 16px;
}
.main-portion-box{
  width: 260px;
  color: #848484;
}
.main-portion-box p{
  font-size: 0.95em;
}
.portion-box{
  width: 100%;
  display: flex;
  margin-top: 5px;
}
.Variations-box{
  width: 80%;
  font-size: 0.90em;
}
.price-box{
  width: 20%;
  display: inline-flex;
  align-items: center;
  justify-content: end;
  font-size: 0.90em;
}

.title-sub{
  font-weight: 600 !important;
}

.namevgiconset{
  display: flex;
}
.cart-recommendeditems{
  width: 100%;
  display: grid;
}
.cart-recommendeditems p{
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}
.cart-featured-scroller {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
  padding-bottom: 10px;
}
@media (min-width: 320px) and (max-width: 768px) {
  .cart-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px !important;
}
}
@media (min-width: 900px) {
    .cart-container {
        grid-template-columns: 2fr 1fr;
    }
   
}

@media (max-width: 600px) {
    .setbgpor.MuiGrid-item {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    .main-portion-box {
      width: 100%;
      color: #848484;
      margin-bottom: 20px;
  }
}


/* CartPage.css */

.cart-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .set-rt {
    width: 100%;
  }
  
  @media (min-width: 900px) {
    .cart-container {
      grid-template-columns: 2fr 1fr;
    }
  }
  
  @media (max-width: 900px) {
    .set-rt {
      order: 2;
    }
    .cart-items {
      order: 1;
    }
  }
  
  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .empty-cart h1 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .empty-cart p {
    font-size: 16px;
    color: #666;
  }

  .item-instruction-style {
      display: flex;
      /* justify-content: space-between; */
      margin-top: 2px;
      height: 40px;
      align-items: center;
  }

  .item-instruction-input {
      width: 45% !important;
  }
  
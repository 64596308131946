.mobile-promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;
    min-height: 40px;
}

.offers-container {
    position: relative;
    width: 100%;
    height: 40px;
    overflow: hidden;
}

.offer-item {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    animation: slideUp 4s ease-in-out;
}

.offer-name {
    font-size: 14px;
    color: #333;
    opacity: 0;
    animation: fadeIn ease-in forwards;
    text-transform: capitalize;
}

.offer-count {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: #555;
}

.offer-count-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; /* Adjust width as needed */
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    12.5% {
        transform: translateY(0%);
    }
    87.5% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.privacy-policy-container {
    padding: 80px 120px;
    max-width: 40%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 80px;
    margin-top: 80px;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.company-logo {
    max-width: 25%; /* Adjust this to your preferred size */
    height: auto;
}

.privacy-title {
    color: #333;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.privacy-section-title {
    color: #444;
    font-size: 1.2rem;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.privacy-policy-container p {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.privacy-policy-container ul li strong {
    color: #333;
}

.static-page-back {
    width: 40px;
    cursor: pointer;
}
.static-page-back img{
    width: 100%;
}

@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 30px 50px;
        max-width: 70%;
    }
}
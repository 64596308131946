.toggle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .toggle-switch {
    display: flex;
    position: relative;
    font-size: 0.9rem;
    width: 100%;
    height: 50px;
    background-color: #f0f0f0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .toggle-option {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000;
    z-index: 1; /* Ensure the text is on top of the slider */
    transition: color 0.3s ease;
  }
  
  .toggle-slider {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: var(--darkblue);
    border-radius: 25px;
    transition: transform 0.3s ease;
    z-index: 0; /* Keep the slider behind the text */
  }
  
  .toggle-slider.right {
    /* transform: translateX(180px); */
    transform: translateX(100%);
  }
  
  .address-input {
    margin-top: 20px;
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.od-card-style {
  width: 80%;
  padding: 15px 20px !important; /* Reduced padding for less spacing */
  margin-bottom: 16px !important;
  border-radius: 12px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
}

.order-title {
  font-weight: bold;
  color: #333;
}

.order-status {
  color: var(--links);
  font-weight: 500;
}

.order-date {
  color: #000;
  margin-bottom: 4px;
}

.history-store-info {
  color: #757575;
  font-weight: 500;
  margin-top: 10px !important;
}

.order-amount {
  font-weight: bold;
  color: #333;
}

.view-order {
  cursor: pointer;
  font-weight: bold !important;
  color: #007200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-large {
  font-size: 40px;
  color: #ff9800;
}

.divider-spacing {
  margin: 8px 0 !important; /* Reduced space between sections */
}
.oh-fastfood-icon svg {
  font-size: 6rem;
  color: #6a994e;
  margin-right: 20px;
}
.icon-btn {
  color: #007200;
}

.order-actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px; /* Ensures icon and text are closer together */
}
.stepper-styles {
  margin-top: 30px;
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  scrollbar-width: thin; /* For modern browsers */
  scrollbar-color: #ccc transparent; /* Customize scrollbar appearance */
  padding-bottom: 10px; /* Adds spacing below the stepper for scrollbar */
}
.stepper-styles::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

.stepper-styles::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.stepper-styles::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}
.stepper-css {
  font-size: 12px;
  color: #888;
}

.text-end {
  text-align: end;
}

.icon-color {
  color: var(--links);
  margin-right: 10px;
}

.qty-price {
  margin-left: 23px;
}
.order-details-block {
  background-color: #efefef;
  padding: 1rem;
  border-radius: 15px;
}
.do-historyicon{
  text-align: right;
  color: var(--links);
  cursor: pointer;
}
.od-backbutton{
  margin-bottom: 20px;
  margin-top: 5px;
  width: 100%;
}
.od-addons-style{
  margin-right: 5rem !important;
}
.od-backbtn-historybtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.up-historyicon{
  margin-right: 10px;
  color: var(--links);
}
.list-date-txtlight{
  color: rgb(136, 136, 136);
  font-weight: light;
}
.order-details-container {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Adds space between rows */
  padding: 16px;
}
.od-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px; /* Space between left and right sections */
}

.od-left-section, .od-right-section {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between the icon and text */
}

.od-left-section {
  flex: 1; /* Ensures left section takes up remaining space */
}

.od-right-section {
  justify-content: flex-end; /* Align right section content to the end */
}

.od-row div {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between elements inside each section */
}
@media (max-width: 768px) {
  .order-details-container {
      padding: 8px; /* Reduce padding for smaller screens */
  }

  .od-row {
      gap: 12px; /* Reduce gap between icon and text for smaller screens */
      flex-direction: column; /* Stack left and right sections on smaller screens */
      align-items: flex-start; /* Align to the start for smaller screens */
  }
  .od-right-section {
    justify-content: flex-start; /* Align to start when stacked */
  }
}
/* Label and date text styles */
.list-date-txtlight span {
  font-size: 0.9rem; /* Default font size */
}

/* Responsive styles for smaller devices */
@media (max-width: 600px) {
  .list-date-txtlight span {
      font-size: 0.8rem; /* Reduced font size for smaller devices */
  }
}
@media (max-width: 500px) {
  .list-date-txtlight span {
      font-size: 0.5rem; /* Reduced font size for smaller devices */
  }
}
/* @media (min-width: 1200px) {
  .od-detail {
    max-width: 66.66% !important;
  }
} */

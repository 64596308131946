/* DiscountCard.css */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: -webkit-fill-available;
    width: auto;
    background: #fff;
    margin: 0 0px 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    .card {
        margin: 0 0px 20px 0 !important;
    }
  }
  @media (min-width: 1281px) and (max-width: 1919px) {
    .card {
        margin: 0 10px 20px 0 !important;
    }
  }
  .code {
    display: flex;
    align-items: center;
  }
  
  .icon {
    background-color: #004d40;
    color: white;
    padding: 8px;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  
  .code-text {
    font-weight: bold;
    font-size: 16px;
  }
  
  .description {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
  }
  
  .actions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
  
  .apply-link, .more-offers {
    color: var(--darkblue);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
  }
  
  .apply-link:hover, .more-offers:hover {
    text-decoration: underline;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    .card {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 16px;
      max-width: -webkit-fill-available;
      width: 100%;
      background: #fff;
      margin: 0 0px 10px 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  }
/* :root {
    --categorytitle:#000;
    --darkblue:#116530;
    --links:#1f7a8c;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:#006630;
    --sidebarnav:#006630;
} */
/* :root {
    --categorytitle:#000;
    --darkblue:#6274E7;
    --links:#1f7a8c;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:linear-gradient(90deg, hsla(352, 83%, 64%, 1) 0%, hsla(230, 90%, 68%, 1) 100%);
    --sidebarnav:linear-gradient(90deg, hsla(352, 83%, 64%, 1) 0%, hsla(230, 90%, 68%, 1) 100%);
} */
/* :root {
    --categorytitle:#000;
    --darkblue:#7EA04D;
    --links:#1f7a8c;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:#335D2D;
    --sidebarnav:#7EA04D;
} */
/* :root {
    --categorytitle:#000;
    --darkblue:#40534C;
    --links:#1f7a8c;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:#1A3636;
    --sidebarnav:#4F6F52;
} */
/* :root {
    --categorytitle:#000;
    --darkblue:#0A4D68;
    --links:#1f7a8c;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:#0A4D68;
    --sidebarnav:#088395;
    --pricecolor: #4c5c68;
} */
:root {
    --categorytitle:#000;
    --darkblue:#0c8c45;
    --links:#0c8c45;
    --borderset:#cc2936;
    --headericons:#000;
    --sidebartitle:#0c8c45;
    --sidebarnav:#0c8c45;
    --pricecolor: #0c8c45;
    --bordercolor:#e41f26;
    --offerhover: #cde8d5;
    --s_color:#cde8d5;
}
body{
    font-family: "Inter", sans-serif !important;
}
/* .css-5lbw0b-MuiTypography-root, .css-ahj2mt-MuiTypography-root, .css-ahj2mt-MuiTypography-root{
    font-family: "Inter", sans-serif !important;
} */
.set-lf {
    min-width: 230px;
    height: auto;
    /* bottom: 20px; */
    width: 100%;
    z-index: 1;
    top: 62px;
    position: sticky;
}
.sbpimgset{
    width: 22px;
    height: 22px;
}
.set-rt {
    min-width: 230px;
    height: auto;
    position: sticky;
    top: 65px;
    width: 100%;
    right: 0;
}
/* .product-grid-container,
.MuiContainer-root {
    margin-bottom: 50px;
} */
/* .css-1yh4fwk-MuiButtonBase-root-MuiListItem-root {
    border-bottom: 1px solid #c4c4c4 !important;
} */
.veg-flag {
    width: 16px;
    height: 16px;
    border: 1px solid #3DA66E;
    text-align: center;
    background-color: #fff;
    margin-right: 7px;
    margin-top: 2px;
    position: relative;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
}
.veg-flag span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #3DA66E;
    border-radius: 50% !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.non-vegflag {
    width: 16px;
    height: 16px;
    border: 1px solid #fd1615;
    text-align: center;
    background-color: #fff;
    margin-right: 7px;
    margin-top: 2px;
    position: relative;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
}
.non-vegflag span {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 7px solid #fd1615;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width: 320px) and (max-width: 768px) {
    .set-lf {
        min-width: 230px;
        height: auto;
        position: fixed;
        bottom: 100px;
        width: 100%;
        z-index: 1;
        top: auto;
    }

    .set-lf button {
        background-color: #489142 !important;
    }
    /* .css-zow5z4-MuiGrid-root {
        margin-left: -12px !important;
        margin-bottom: 140px;
    }
    .css-knqc4i-MuiDialogActions-root {
        right: 0%;
        top: auto;
        bottom: 15px;
        margin: auto;
        z-index: 1;
    }
    .css-knqc4i-MuiDialogActions-root button{
        color: #000 !important;
    }
    .css-knqc4i-MuiDialogActions-root button {
        color: #fff;
    } */
}
.search-on-map{
    padding: 10px;
    border: 1px solid #c3c3c3;
    border-radius: 7px;
    cursor: pointer;
}
.search-bar-main-box{
    width: 320px;
    height: 42px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 7px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    color: #7f7f7f;
    font-size: 0.8rem;
}
.search-bar-main-box p{
    font-size: 0.8rem;
    margin: 0;
}
.pro-title{
    font-weight: bold !important;
    margin-bottom: 2px !important;
    margin-right: 10px !important;
    width: 100% !important;
    font-size: 0.9rem !important;
}
.addcart-in-de-button{
    /* display: flex; */
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    background: #ecedea;
    border-radius: 50px;
}
.pro-contentbox{
    width: 100%;
    /* padding-right: 10px; */
}
.pro-contentimg{
    width: 100%;
}
.pro-contentimg img{
    width: 100% !important;
    height: 220px !important;
}
.chips-set{
    display: flex;
}
.chips-not-avelable{
    margin-left: 5px;
}
.product-box-wc{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.search-bar-main-box svg{
    padding: 11px;
}
.store-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #fff;
    width: 43%;
    min-height: 40px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.store-info .css-1776fzg-MuiInputBase-root{
    border: 1px solid #ccc;
    border-radius: 5px;
}
.store-details {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
}
.checkboxnew-outer {
    display: flex;
    justify-content: right;
}
.checkboxnew-outer .chek:last-child {
    margin-right: 0px;
}
.vn-box {
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    font-size: 13px;
}
.vn-box span {
    text-align: center;
    padding: 3px 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.veg-flag span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #3DA66E;
    border-radius: 50% !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.veg-flag {
    width: 16px;
    height: 16px;
    border: 1px solid #3DA66E;
    text-align: center;
    background-color: #fff;
    margin-right: 7px;
    margin-top: 2px;
    position: relative;
    border-radius: 3px;
}
.veg-flag span {
    display: inline-block !important;
    width: 7px !important;
    height: 7px !important;
    padding: 0px !important;
}
.sna-setup{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 6px;
}
.store-name {
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0;
    width: 100%;
}
.store-address{
    font-size: 0.65rem !important;
    font-weight: 500 !important;
    margin: 0 !important;
    color: #7f7f7f !important;
    width: 100%;
}
.icon-names span{
    cursor: pointer;
    font-size: 0.8rem;
}
.set-location-icon{
    margin-top: 5px;
}
.set-store-name-icon{
    /* width: 300px; */
    width: 100%;
}
.icon-names{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.icon-names svg{
    cursor: pointer;
    margin-right: 5px;
    height: auto;
    width: 18px;
}
.read-more-home{
    cursor: pointer;
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--links);
}
.item-cart-category {
    padding-left: 0 !important;
    padding-top: 0 !important;
    display: grid;
}
.store-status {
    color: red;
    font-weight: bold;
}
.home-category-grid{
    margin-left: 0 !important;
}
.store-status.closed {
    color: red;
    border: 1px solid red;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 0.7rem;
}

.store-status.open {
    color: green;
    border: 1px solid green;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 0.6rem;
}

.header-container {
    /* display: flex; */
    /* align-items: center;
    justify-content:space-between; */
    width: 100%;
    /* gap: 10px; */
}

.store-rating {
    display: flex;
    align-items: center;
}

.rating {
    color: #ffb400;
    font-weight: bold;
    margin-right: 5px;
}

.reviews {
    color: #777;
}
.next-opening-time {
    font-size: 0.99em;
    color: gray;
}
.setup-address-store{
    display: flex;
    gap: 5px;
}
.category-header:not(.expanded),
.subcategory-header:not(.expanded) {
    margin: 20px 0; /* Add margin to keep spacing */
}

@media (min-width: 320px) and (max-width: 599px) {
    .item-cart-category {
        padding-left: 16px;
        margin-right: 10px;
    }
}
@media (min-width: 600px) and (max-width: 1023px) {
    .item-cart-category {
        padding-left: 16px;
        margin-right: 0px;
    }
}
@media (min-width: 320px) and (max-width: 1023px) {

    .header-container{
        display: block;
    }
    .search-bar-main-box {
        width: 100%;
    }
    .store-info{
        width: auto;
        margin-bottom: 0;
        background-color: #fafafa;
    }
    /* .css-1utkld7-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        width: 100%;
    }
    .css-1776fzg-MuiInputBase-root{
        width: 80% !important;
    } */
    .vn-box {
        width: 130px;
    }
    .setup-address-store {
        display: flex;
        gap: 0px;
        flex-wrap: wrap;
    }
    .store-info {
        padding: 0px 10px;
        width: 100%;
    }
    /* .css-mhc70k-MuiGrid-root{
        width: calc(100% + 30px) !important;
    } */
    .header-container{
        /* position: sticky; */
        top: -30px;
        z-index: 1;
        margin-bottom: 20px;
    }
    .product-box-wc{
        flex-wrap: nowrap;
        gap: 10px;
    }
    .pro-contentimg img{
        height: 160px !important;
    }
    .circular-skeleton {
        margin-left: auto; /* Pushes the skeleton to the end of the container */
    }
    /* .css-1utkld7-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        display: none !important;
    }
    .css-k6jz7d-MuiModal-root-MuiDialog-root .MuiDialogContent-root img{
        width: 100% !important;
        height: auto !important;
    } */
    .new-seprators{
        width: auto !important;
    }
    
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .store-info{
        /* width: 36%; */
        width: 47%;
    }
    /* .css-1776fzg-MuiInputBase-root{
        width: 100%;
    } */
    .search-bar-main-box {
        width: 200px;
    }
}
@media (min-width: 1281px) and (max-width: 2080px) {
    .store-info{
        width: 50%;
    }
}
.category-header {
    scroll-margin-top: 85px;
    margin: 20px 0;
}
.subcategory-header {
    scroll-margin-top: 85px;
    margin: 15px 0; /* Add spacing between collapsed subcategories */
}

@media (max-width: 900px) {
    .category-header {
        scroll-margin-top: 88px; /* For large mobile screens */
    }
}

/* Small phones */
@media (max-width: 400px) {
    .category-header {
        scroll-margin-top: 85px; /* Highest margin for small mobile screens */
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .set-lf button {
        background-color: #489142 !important;
    }
    .set-lf {
        min-width: 230px;
        height: auto;
        position: fixed;
        bottom: 100px;
        width: 100%;
        z-index: 1;
        top: auto;
    }
    .sidebar {
        display: none;
    }
    .sidebar-toggle-button {
        display: block;
        position: fixed !important;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
    }
}
/* @media (min-width: 600px) and (max-width: 899px) {
    .sidebar {
        display: none;
    }
} */
.pf-order-error-container{
    width: 40% !important;
    margin: 0 auto !important;
    padding: 20px !important;
    text-align: center !important;
    border-radius: 10px !important;
  }
  
  .pf-icon-container {
    margin-bottom: 15px !important;
  }
  
  .pf-error-icon {
    font-size: 6.8rem !important;
    color: #f44336 !important;
  }
  
  .pf-title {
    color: #333 !important;
    margin-bottom: 10px !important;
    font-weight: 700 !important;
  }
  
  .pf-order-id {
    color: #777 !important;
    margin-bottom: 20px !important;
    font-size: 0.99rem !important;
  }
  
  .pf-order-id span{
    color: #777 !important;
    font-size: 0.99rem !important;
  }
  .pf-button-block{
    margin-top: 1rem;
    gap: 3rem;
    display: flex;
    justify-content: center !important;
  }
  .pf-go-to-menu-button {
    gap: 8px; /* Space between icon and text */
    font-size: 0.7rem !important;
    text-transform: none !important;
    width: 50% !important;
    margin-bottom: 20px !important;
  }

  @media (min-width: 320px) and (max-width: 599px) {
    .pf-order-error-container{
      width: 80% !important;
    }
  
    .pf-title {
      font-size: 1rem !important;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    .pf-order-error-container{
      width: 50% !important;
    }
  
    .pf-title {
      font-size: 1.2rem !important;
    }
  }
  @media (min-width: 1025px) and (max-width: 1600px) {
    .pf-order-error-container{
      width: 40% !important;
    }
  
    .pf-title {
      font-size: 1.5rem !important;
    }
  }
/* TermsAndConditions.css */
.terms-container {
    max-width: 40%;
    margin: 20px auto;
    padding: 80px 120px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 80px;
    margin-top: 80px;
  }
  
  .terms-title {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .terms-list {
    list-style-type: decimal;
    /* margin-left: 20px; */
    line-height: 1.6;
    padding: 0;
    color: #555;
  }
  
  .terms-list li {
    margin-bottom: 15px;
    font-size: 0.9rem;
  }
  
  .terms-list strong {
    color: #333;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .terms-container {
        padding: 30px 50px;
        max-width: 70%;
    }
  }
  @media (max-width: 768px) {
    .terms-title {
      font-size: 2em; /* Adjust title size on smaller screens */
    }
  
    .terms-list {
      margin-left: 15px; /* Reduce left margin */
    }
  }
  
  @media (max-width: 480px) {
    .terms-title {
      font-size: 1.5em; /* Further reduce title size */
    }
  
    .terms-list li {
      margin-bottom: 10px; /* Reduce spacing between list items */
    }
  }
  
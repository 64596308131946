/* FreeDeliveryNotification.css */
.notification {
    display: flex;
    align-items: center;
    background-color: #f0fff4;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 0;
    margin: 0px 0px 10px 0;
  }
  
  .icon {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 20px !important;
    padding: 10px;
    border-radius: 50%;
    margin-right: 15px;
    align-items: center;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 0.98;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-weight: bold;
    color: #388e3c;
  }
  
  .subtitle {
    font-size: 14px;
    color: #388e3c;
  }
  
  .highlight {
    font-weight: bold;
  }
  
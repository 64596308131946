.custom-offer-drawer {
    width: 50% !important; /* Set drawer content width to 80% on large screens */
    margin: 0 auto !important; /* Center the drawer horizontally */
    border-top-left-radius: 16px !important; /* Add top-left border radius */
    border-top-right-radius: 16px !important; /* Add top-right border radius */
    font-family: "Inter", sans-serif !important;
}

/* Box styling inside the drawer */
.drawer-offer-box {
    /* height: 27rem; */
    min-height: 21rem;
    padding-left: 30px;
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 15px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.sm-offer-name {
    font-size: 0.86rem !important;
    font-weight: 600 !important;
    text-transform: capitalize;
}

.sm-offer-address {
    font-size: 0.8rem !important;
}

/* Apply 99% width for screens below 600px (mobile and small tablets) */
@media (max-width: 600px) {
    .custom-offer-drawer {
        width: 99% !important; /* Set drawer content width to 99% for small screens */
        margin: 0 auto !important; /* Center the drawer horizontally */
    }
    .house-landmark {
        display: block;
    }
    .drawer-offer-box {
        min-height: 18rem;
    }
    .delivery-input-fields {
        margin-right: 0rem;
    }
}

.cart-popup {
    position: fixed;
    bottom: -100%; /* Start off-screen at the bottom */
    left: 50%;
    transform: translateX(-50%);
    background-color: #00942e;
    /* background: linear-gradient(90deg, hsla(211, 96%, 62%, 1) 0%, hsla(295, 94%, 76%, 1) 100%); */
    /* background: linear-gradient(90deg, hsla(197, 82%, 22%, 1) 0%, hsla(188, 90%, 31%, 1) 100%); */
    color: #fff;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    width: 20rem;
    transition: bottom 0.5s ease-in-out; /* Animation for sliding in and out */
}

.cart-popup.show {
    bottom: 10%; /* Slide to center of the screen */
}

.cart-popup.hide {
    bottom: -100%; /* Slide back down to off-screen */
}

.cart-popup-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.view-cart-button {
    background-color: #ffffff;
    color: #000;
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 1.25rem;
    cursor: pointer;
    margin-left: 0.75rem;
}

.close-popup-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 1.25rem; /* Adjust font size using rem */
    cursor: pointer;
    margin-left: 0.625rem;
}

@media (max-width: 600px) {
    .cart-popup {
        width: 16rem; /* Smaller width for mobile devices */
        padding: 0.75rem 1rem; /* Adjust padding */
    }
}